import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const OnboardingRedirect = () => {
	const navigate = useNavigate();

	useEffect(() => {
		// Check if we are on the onboarding page
		if (window.location.pathname === "/onboarding") {
			window.location.href = "https://forms.gle/zH2cFyhZKJFtqE84A";
		} else {
			// Redirect to home if not on /onboarding
			navigate("/");
		}
	}, [navigate]);

	return null; // No UI needed
};

export default OnboardingRedirect;

import React from "react";
import "./App.css";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Home from "./components/home/Home";
// import Qualification from "./components/qualification/Qualification";
import ScrollUp from "./components/scrollup/ScrollUp";
import Services from "./components/services/Services";

const App = () => {
	if (true) {
		return (
			<div className='maintenance'>
				<p>Website under maintenance</p>
			</div>
		);
	}
	return (
		<div>
			<Header />
			<main className='main'>
				<Home />
				<About />
				<Services />
				{/* <Qualification /> */}
				<Contact />
			</main>

			<Footer />
			<ScrollUp />
		</div>
	);
};

export default App;
